import SecureStorage from "secure-web-storage";
import CryptoJS, { AES, enc } from "crypto-js";

export function Encrypt(sessionnombre, sessionvalor) {
  let secureStorage = new SecureStorage(sessionStorage, {
    hash: function hash(key) {
      key = CryptoJS.SHA256(key, process.env.REACT_APP_SECRET_KEY);
      return key.toString();
    },
    encrypt: function encrypt(data) {
      data = AES.encrypt(data, process.env.REACT_APP_SECRET_KEY);
      data = data.toString();
      return data;
    },
  });

  secureStorage.setItem(sessionnombre, sessionvalor);
}

export function Decrypt(sessionnombre) {
  let secureStorage = new SecureStorage(sessionStorage, {
    hash: function hash(key) {
      key = CryptoJS.SHA256(key, process.env.REACT_APP_SECRET_KEY);
      return key.toString();
    },
    decrypt: function decrypt(data) {
      data = AES.decrypt(data, process.env.REACT_APP_SECRET_KEY);
      data = data.toString(enc.Utf8);
      return data;
    },
  });

  return secureStorage.getItem(sessionnombre);
}