import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Login from "./login/Login";
import Listado from "./login/Listado";
import Informacion from "./login/Informacion";
import Nombre from "./login/Nombre";

// import reportWebVitals from "./reportWebVitals";

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
let target = document.getElementById("pr-root");
if (target) {
  ReactDOM.render(<Login />, target);
}

target = document.getElementById("pr-listado");
if (target) {
  ReactDOM.render(<Listado />, target);
}

target = document.getElementById("pr-informacion");
if (target) {
  ReactDOM.render(<Informacion />, target);
}

target = document.getElementById("pr-nombre");
if (target) {
  ReactDOM.render(<Nombre />, target);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
