import React, { useState, useEffect } from "react";
import clienteAxios from "../config/axios";
import { Decrypt } from "../config/EncryptDecrypt";
import './Listado.css';
const Listado = () => {
  const [puntos, guardarPuntos] = useState(0);
  const [listadoCompras, guardarListadoCompras] = useState([]);
  const [mostrarlistado, guardarMostrarlistado] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [visitas, setVisitas] = useState(null);
  // const [mostrarpuntos, guardarMostrarpuntos] = useState(false);
  const [cargar, guardarCargar] = useState(6);
  useEffect(() => {

    const obtenerCompras = async () => {
      /*const strrespuesta = `{
        "acumulated_points": "560.00",
        "details": [
            {
                "Sucursal": "Reyes de CAFFENIO",
                "Fecha": "2023-08-10T16:17:39.970",
                "Metodo": "Compra puntos de reyes",
                "Puntos": "16.00"
            },
            {
                "Sucursal": "Reyes de CAFFENIO",
                "Fecha": "2023-08-10T15:49:56.850",
                "Metodo": "Compra puntos de reyes",
                "Puntos": "8.00"
            },
            {
                "Sucursal": "Reyes de CAFFENIO",
                "Fecha": "2023-08-07T14:03:30.983",
                "Metodo": "Compra puntos de reyes",
                "Puntos": "8.00"
            },
            {
                "Sucursal": "Reyes de CAFFENIO",
                "Fecha": "2023-08-07T10:52:10.703",
                "Metodo": "Compra puntos de reyes",
                "Puntos": "64.00"
            },
            {
                "Sucursal": "CAFFENIO",
                "Fecha": "2023-08-07T09:27:13.177",
                "Metodo": "Puntos extras Período 3",
                "Puntos": "90.00"
            },
            {
                "Sucursal": "CAFFENIO",
                "Fecha": "2023-08-07T09:27:13.173",
                "Metodo": "Puntos extras Período 1",
                "Puntos": "30.00"
            },
            {
                "Sucursal": "CAFFENIO",
                "Fecha": "2023-08-07T09:27:13.173",
                "Metodo": "Puntos extras Período 2",
                "Puntos": "60.00"
            },
            {
                "Sucursal": "CAFFENIO",
                "Fecha": "2023-08-07T08:11:15.190",
                "Metodo": "Compra Membresía MiCAFFENIO+",
                "Puntos": "200.00"
            },
            {
                "Sucursal": "Caffenio Planta NUEVO",
                "Fecha": "2022-10-30T22:46:45.230",
                "Metodo": "Código QR",
                "Puntos": "0.00"
            },
            {
                "Sucursal": "Caffenio Mar de Cortés Guaymas",
                "Fecha": "2022-10-30T12:02:17.173",
                "Metodo": "Código QR",
                "Puntos": "0.00"
            },
            {
                "Sucursal": "Caffenio Planta NUEVO",
                "Fecha": "2022-10-29T20:05:39.760",
                "Metodo": "Código QR",
                "Puntos": "0.00"
            },
            {
                "Sucursal": "Caffenio Mar de Cortés Guaymas",
                "Fecha": "2022-10-29T15:59:50.397",
                "Metodo": "Código QR",
                "Puntos": "8.00"
            },
            {
                "Sucursal": "Caffenio Planta NUEVO",
                "Fecha": "2022-10-29T13:58:59.190",
                "Metodo": "Código QR",
                "Puntos": "0.00"
            },
            {
                "Sucursal": "Caffenio Mar de Cortés Guaymas",
                "Fecha": "2022-10-27T12:37:40.707",
                "Metodo": "Código QR",
                "Puntos": "8.00"
            },
            {
                "Sucursal": "Caffenio Mar de Cortés Guaymas",
                "Fecha": "2022-10-25T19:51:18.450",
                "Metodo": "Código QR",
                "Puntos": "0.00"
            },
            {
                "Sucursal": "Caffenio Planta NUEVO",
                "Fecha": "2022-10-24T08:00:13.500",
                "Metodo": "Código QR",
                "Puntos": "0.00"
            },
            {
                "Sucursal": "Caffenio Mar de Cortés Guaymas",
                "Fecha": "2022-10-23T15:25:26.050",
                "Metodo": "Compra Móvil",
                "Puntos": "0.00"
            },
            {
                "Sucursal": "Caffenio Mar de Cortés Guaymas",
                "Fecha": "2022-10-23T11:17:59.957",
                "Metodo": "Código QR",
                "Puntos": "0.00"
            },
            {
                "Sucursal": "Caffenio Mar de Cortés Guaymas",
                "Fecha": "2022-10-23T11:01:36.380",
                "Metodo": "Compra Móvil",
                "Puntos": "0.00"
            },
            {
                "Sucursal": "Caffenio Mar de Cortés Guaymas",
                "Fecha": "2022-10-22T14:49:26.033",
                "Metodo": "Código QR",
                "Puntos": "4.00"
            },
            {
                "Sucursal": "Caffenio Mar de Cortés Guaymas",
                "Fecha": "2022-10-21T08:58:23.760",
                "Metodo": "Código QR",
                "Puntos": "4.00"
            },
            {
                "Sucursal": "Caffenio Mar de Cortés Guaymas",
                "Fecha": "2022-10-20T12:06:34.353",
                "Metodo": "Código QR",
                "Puntos": "0.00"
            },
            {
                "Sucursal": "Caffenio Mar de Cortés Guaymas",
                "Fecha": "2022-10-18T18:37:36.987",
                "Metodo": "Código QR",
                "Puntos": "8.00"
            },
            {
                "Sucursal": "Caffenio Loma Linda",
                "Fecha": "2022-10-16T13:10:32.187",
                "Metodo": "Código QR",
                "Puntos": "8.00"
            },
            {
                "Sucursal": "Caffenio Mar de Cortés Guaymas",
                "Fecha": "2022-10-14T12:47:49.007",
                "Metodo": "Código QR",
                "Puntos": "8.00"
            },
            {
                "Sucursal": "Caffenio Mar de Cortés Guaymas",
                "Fecha": "2022-10-13T19:27:43.760",
                "Metodo": "Código QR",
                "Puntos": "0.00"
            },
            {
                "Sucursal": "Caffenio Mar de Cortés Guaymas",
                "Fecha": "2022-10-10T21:14:18.627",
                "Metodo": "Código QR",
                "Puntos": "4.00"
            },
            {
                "Sucursal": "Caffenio Mar de Cortés Guaymas",
                "Fecha": "2022-10-08T14:57:09.517",
                "Metodo": "Código QR",
                "Puntos": "8.00"
            },
            {
                "Sucursal": "Caffenio Mar de Cortés Guaymas",
                "Fecha": "2022-10-06T11:20:10.980",
                "Metodo": "Código QR",
                "Puntos": "8.00"
            },
            {
                "Sucursal": "Caffenio Mar de Cortés Guaymas",
                "Fecha": "2022-10-05T19:10:59.803",
                "Metodo": "Código QR",
                "Puntos": "0.00"
            },
            {
                "Sucursal": "Caffenio Mar de Cortés Guaymas",
                "Fecha": "2022-10-04T13:57:28.877",
                "Metodo": "Código QR",
                "Puntos": "8.00"
            },
            {
                "Sucursal": "Caffenio Mar de Cortés Guaymas",
                "Fecha": "2022-10-02T15:38:28.027",
                "Metodo": "Código QR",
                "Puntos": "4.00"
            },
            {
                "Sucursal": "Caffenio Mar de Cortés Guaymas",
                "Fecha": "2022-10-01T21:31:16.487",
                "Metodo": "Código QR",
                "Puntos": "4.00"
            }
        ],
        "TotalVisitDrive": "25/60 VISITAS",
        "PercentageDrive": "41",
        "PercentageFloatDrive": "0.4"
      }`*/

      //var respuesta = JSON.parse(strrespuesta);

      await clienteAxios("/api/reyes/2023/record/", {
        headers: {
          Authorization: "Bearer " + Decrypt("token"),
        },
      })
        .then((respuesta) => {
          if (respuesta.data.details.length > 0) {
            guardarMostrarlistado(true);
          }
          if (cargar >= respuesta.data.details.length) {
            guardarMostrarlistado(false);
          }
          const puntos_acum = respuesta.data.acumulated_points;
          guardarPuntos(Math.floor(respuesta.data.acumulated_points));
          guardarListadoCompras(respuesta.data.details);

          setVisitas(respuesta.data.TotalVisitDrive)

          const percentageDrive = parseInt(respuesta.data.PercentageDrive);


          // const parsedPercentage = parseFloat(respuesta.data.PercentageFloatDrive) * 100;
          // console.log('parsedPercentage', parsedPercentage);
          setPercentage(percentageDrive);
        })

        .catch((error) => {
          console.log(error);
        });
    };
    obtenerCompras();

    const interval = setInterval(obtenerCompras, 5 * 60 * 1000); // Actualizar cada 5 minutos
    console.log('interval ', interval);
    return () => clearInterval(interval); // Limpiar intervalo al desmontar el componente

  }, [cargar]);

  const cargarMas = (e) => {
    e.preventDefault();
    guardarCargar(cargar + 6);
  }

  const usuario = Decrypt("usuario");
  const { Nombre } = usuario ?? {};

  return (
    <>

      <div className="col-md-4 col-sm-6 p-xs-0">

        <div className="cajitas">

          <div className="box">
            <div className="row rm-margin-x">
              <div className="col-xs-5 col-sm-6 col-md-6 col-lg-12">

                {/* <img
                  src="https://reyes.caffenio.com/wp-content/uploads/2022/08/Avatar.png"
                  alt="Avatar"
                /> */}
              </div>
              <div className="col-lg-12">
                <h3 className="holanombre">¡Hola, {Nombre != "" || Nombre != null ? Nombre : 'No hay dato registrado'}!</h3>
                <div className="separados">
                  <div className="tamano1">
                    <p className="cuentascon">Cuentas con:</p>
                  </div>

                  <span className="negro">{puntos != "" || puntos != null ? puntos : "0"} </span>
                  <span className="verde">PUNTOS </span>
                </div>
              </div>
              <div>
                <p className="texto-visitas">¡Haz que tus visitas cuenten!</p>






                <div className="progress-container">
                  <div className="progress-bar" style={{ width: `${percentage}%` }}>
                    <div className="progress-icon"></div>
                  </div>
                </div>

                <div className="contenedor-visitas">
                  <span className="visitas">{visitas}</span>
                  <span className="texto-visitas">¡Meta!</span>
                </div>





              </div>
            </div>

          </div>
        </div>

        <div className="cajitas">
          <div className="box box-sm">
            <div className="row rm-margin-x">
              <div className="col-xs-5 col-sm-6 col-md-6 col-lg-4">
                <img
                  src="https://reyes.caffenio.com/wp-content/uploads/2023/08/reyes-de-caffenio.png"
                  alt="movil"
                />
              </div>
              <div className="col-xs-7 col-sm-6 col-md-6 col-lg-8">
                <h4 className="textomedio text-bold">¿Cómo acumular puntos?</h4>

                <a className="botonlink btn-dinamica text-bold" href="https://reyes.caffenio.com/#mecanicas" target="_blank">Acceder</a>
              </div>
            </div>
          </div>
        </div>

        <div className="cajitas" style={{display: 'none'}}>
          <div className="box box-sm">
            <div className="row">
              <div className="col-xs-5 col-sm-6 col-md-6 col-lg-4">
                <img
                  src="https://reyes.caffenio.com/wp-content/uploads/2023/08/Canje.png"
                  alt="Monedero" />

              </div>
              <div className="col-xs-7 col-sm-6 col-md-6 col-lg-8">
                <h4 className="textomedio text-bold">Aprende a canjear puntos</h4>

                <a className="botonlink btn-video text-bold" href="">Acceder</a>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="box box-historial col-md-8 col-sm-6">

        <div className="tulohistorial">
          <h1 className="titulo-movimientos">Últimos movimientos realizados:</h1>
        </div>


        <div className="row">
          <div className="espaciotabla listado-contenedor-lista">

            <table className="table table-striped">
              <tbody>
                {
                  listadoCompras.length > 0 ? (
                    listadoCompras.map((listado, index) => (
                      <>
                        {index <= cargar && (
                          <>
                            <tr>
                              <td className="tablatexto text-dark border-left">{listado.Sucursal != "" || listado.Sucursal != null ? listado.Sucursal : "No se ha especificado"}:<br />
                                <div className="textohistorial">
                                  {listado.Metodo != "" || listado.Metodo != null ? listado.Metodo : "No se ha especificado"}
                                </div>
                              </td>
                              <td className="tablatexto border-right">
                                <div className="tablaizquierda">
                                  <span className="text-dark">{listado.Puntos.slice(0, -3)} puntos </span>
                                  <div className="textohistorial">

                                    {listado.Fecha.substr(8, 2) +
                                      "/" +
                                      listado.Fecha.substr(5, 2) +
                                      "/" +
                                      listado.Fecha.substr(0, 4)}

                                  </div>
                                </div>
                              </td>

                            </tr>
                          </>
                        )}
                      </>
                    ))) : (
                    <>
                      <td colspan="6">
                        <div className="listado-mensaje">
                          "No hay compras realizadas"
                        </div>
                      </td>
                    </>
                  )
                }
              </tbody>


            </table>
          </div>


        </div>

        <div className="centrarculumnas">
          <div>
            {mostrarlistado ? (<a className="botonlinkrojo" href="#" onClick={cargarMas}>Ver más</a>) : (<></>)
            }
          </div>
        </div>
      </div>


      {/* Lo que hizo Froi
      <div className="row listado-prepago listado-row">
        <div className="col-md-6">
          <div>Tu prepago:</div>
          <div>${Saldo}</div>
        </div>
        <div className="col-md-6 listado-prepago-bold">
          <div>Tu monedero</div>
          <div>${SaldoMonedero}</div>
        </div>
      </div>
      <div className="listado-alineacion-centro">
        <div className="listado-contenedor-puntos">
          <div className="listado-s1-8">Tus puntos son:</div>
          <div className="listado-w700">{puntos > 0 ? puntos : 0}</div>
        </div>
      </div>
      <div className="listado-texto-lugares">
        Lugares en donde has ganado puntos:
      </div>
      <div className="listado-alineacion-centro">
        <div className="listado-contenedor-lista">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Sucursal</th>
                <th>Fecha</th>
                <th>Hora</th>
                <th>Método</th>
                <th>Puntaje</th>
              </tr>
            </thead>
            <tbody>
              {mostrarlistado ? (
                listadoCompras.map((listado) => (
                  <tr>
                    <td>
                      <img src="/wp-content/uploads/2021/08/drive.png" alt="" />
                    </td>
                    <td>{listado.Sucursal}</td>
                    <td>
                      {listado.Fecha.substr(8, 2) +
                        "/" +
                        listado.Fecha.substr(5, 2) +
                        "/" +
                        listado.Fecha.substr(0, 4)}
                    </td>
                    <td>
                      {listado.Fecha.substr(11, 2) +
                        ":" +
                        listado.Fecha.substr(14, 2) +
                        ":" +
                        listado.Fecha.substr(17, 2) +
                        " hrs."}
                    </td>
                    <td>{listado.Metodo}</td>
                    <td>{listado.Puntos}</td>
                  </tr>
                ))
              ) : (
                <td colspan="6">
                  <div className="listado-mensaje">
                    "No hay compras realizadas"
                  </div>
                </td>
              )}
            </tbody>
          </table>
        </div>
      </div> */}
    </>
  );
};

export default Listado;
