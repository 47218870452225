import React, { useState, useEffect } from "react";
import clienteAxios from "../config/axios";
import { Decrypt } from "../config/EncryptDecrypt";
import Error from "../components/Error";
import "./Login.css";
const Informacion = () => {
    const [usuario, guardarUsuario] = useState({});
    const [validado, guardarValidado] = useState(false);
    // const [datosCorrectos, guardarDatosCorrectos] = useState(false);
    const [terminos, guardarTerminos] = useState(false);
    const [error, guardarError] = useState(false);
    const [mensaje, guardarMensaje] = useState("");
    const [firsttime, guardarFirsttime] = useState(false);

    useEffect(() => {
        const datos = async () => {
            await clienteAxios("/api/reyes/2023/validate/", {
                headers: {
                    Authorization: "Bearer " + Decrypt("token"),
                },
            })
                .then((response) => {
                    guardarUsuario(response.data.Info);
                    guardarValidado(response.data.is_in_program);
                })
                .catch((error) => {
                    console.log(
                        "Información incorrecta"
                    );
                });
        };
        datos();
    }, [])

    const agregarConcursante = async () => {
        let data = {};
        await clienteAxios
            .post("/api/reyes/2023/AddProgram?check_data=0", data, {
                headers: {
                    Authorization: "Bearer " + Decrypt("token"),
                },
            })
            .then((respuesta) => {
                guardarFirsttime(true);
            })
            .catch((error) => {
                console.log(
                    "No puede iniciar sesión con las credenciales proporcionadas."
                );
            });
    };


    const verifyData = async (e) => {
        e.preventDefault();
        if (terminos) {
            await clienteAxios("/api/reyes/2023/validate/", {
                headers: {
                    Authorization: "Bearer " + Decrypt("token"),
                },
            })
                .then((respuesta) => {
                    if (!respuesta.data.is_in_program) {
                        agregarConcursante();
                    }
                    agregarConcursante();
                    window.location.href = "felicidades/";
                })
                .catch((error) => {
                    console.log(
                        "No puede iniciar sesión con las credenciales proporcionadas."
                    );
                });

        } else {
            console.log("Falta capturar información");
            if (!terminos) {
                guardarError(true);
                guardarMensaje("Confirme los términos y condiciones.");
            }
            // else if (!datosCorrectos) {
            //     guardarError(true);
            //     guardarMensaje("Confirme que sus datos sean correctos.");
            // }
        }
    }

    // const onChangeDatosCorrectos = (e) => {
    //     guardarDatosCorrectos(e.target.checked);
    // }

    const onChangeTerminos = (e) => {
        console.log('Terminos ',e.target.checked);
        guardarTerminos(e.target.checked);
    }

    return (
        <>

            <form className="form-login" onSubmit={verifyData}>
                <div className="form-group login-grupo">

                    <div className="px-3 xs-pl-10">
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <p className="title-verify">Nombre (s):</p>
                                <p className="result-data">{usuario.Nombre != "" || usuario.Nombre != null ? usuario.Nombre : 'No hay dato registrado'}</p>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <p className="title-verify">Apellido (s):</p>

                                <p className="result-data">
                                    {usuario.ApellidoP != "" ? usuario.ApellidoP : ''}
                                    {usuario.ApellidoM != "" ? usuario.ApellidoM : ''}
                                    {usuario.ApellidoM != "" || usuario.ApellidoP != "" ? '' : 'No hay dato registrado'}

                                </p>
                            </div>
                        </div>
                        <p className="title-verify">Correo electrónico:</p>
                        <p className="result-data">{usuario.Correo != "" ? usuario.Correo : 'No hay dato registrado'}</p>

                        <p className="title-verify">Teléfono celular:</p>
                        <p className="result-data">{usuario.Telefono != "" ? usuario.Telefono : 'No hay dato registrado'}</p>

                        {/* <div className="text-align-center">
                            <input type="checkbox" name="confirm" id="confirm" onChange={onChangeDatosCorrectos} />
                            <span className="confirm">Confirmo que mis datos son correctos</span>
                        </div> */}
                    </div>
{/* 
                    <div className="yellow-box">
                        <p className="title-verify">¿Tus datos son incorrectos?</p>
                        <p className="phone-box">Llamanos al <a href="tel:*2233" target="_blank"><u>*2233</u></a> o mándanos un mensaje en WhatsApp
                            <a href="https://api.whatsapp.com/send?phone=+5216621930293&text=Hola,%20me%20gustar%C3%ADa%20actualizar%20mis%20datos%20de%20MICAFFENIO..." target="_blank">
                                <u> (662)1930293 </u></a></p>
                        <span className="btn-whtapp">
                            <a href="https://api.whatsapp.com/send?phone=+5216621930293&text=Hola,%20me%20gustar%C3%ADa%20actualizar%20mis%20datos%20de%20MICAFFENIO..." target="_blank">
                                <i aria-hidden="true" className="fab fa-whatsapp"></i> Whatsapp
                            </a>
                        </span>

                    </div> */}

                    <div className="pt-5 text-right">
                        <input type="checkbox" name="acept" id="acept" onChange={onChangeTerminos} />
                        <span className="terms">Acepto los
                            <a href="https://reyes.caffenio.com/terminos-y-condiciones-3/" target="_blank"><u>términos y condiciones</u>
                            </a> para participar en <br /> Reyes de CAFFENIO 2023</span>
                    </div>

                    <div className="login-align-end">
                        <input
                            id="btnIniciasesion"
                            type="submit"
                            className="form-control acceder-boton"
                            value="Quiero participar"
                        />
                    </div>

                </div>
            </form>

            {error && <Error mensaje={mensaje} guardarError={guardarError} />}

        </>
    )
}

export default Informacion;